<script setup lang="ts">
import { UI05Button, UI05Form, UI05FormElement, UI05InputText } from 'ui-05'
import type { StatusType } from '~/type/StatusType'
import type ErrorType from '~~/type/Error'

defineProps<{
  status?: StatusType
  errorValue?: ErrorType
}>()

const emit = defineEmits<{
  (e: 'submit', value: string): void
}>()

const code = ref('')

function onSubmit(code: string) {
  emit('submit', code)
}
</script>

<template>
  <div class="confirm">
    <ValidationForm v-slot="{ handleSubmit }" as="div">
      <UI05Form
        type="inline"
        :adaptive-on900="true"
        class="confirm__form"
        @submit="handleSubmit($event, onSubmit)"
      >
        <ValidationField
          v-slot="{ field, errors }"
          rules="required|length:6"
          name="код подтверждения"
          class="confirm__code"
        >
          <UI05FormElement
            title="Код из СМС"
            autocomplete="off"
            :error="errors[0] || (status === 'error' ? errorValue?.description : null)"
          >
            <UI05InputText v-model="code" v-bind="field" />
          </UI05FormElement>
        </ValidationField>

        <UI05Button
          v-if="status === 'success'"
          size="39"
          theme="submited"
          icon="tick"
          disabled
        >
          Телефон подтвержден
        </UI05Button>
        <UI05Button
          v-else-if="status === 'loading'"
          size="39"
          theme="red"
          loading
        />
        <UI05Button
          v-else
          theme="red"
          size="39"
        >
          Подтвердить телефон
        </UI05Button>
      </UI05Form>
    </ValidationForm>
  </div>
</template>

<style lang="postcss" scoped>
.confirm {

  &__form {
    flex-wrap: wrap;
  }

  &__error {
    width: 100%;
  }
}
</style>
